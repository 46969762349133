.home {
  min-height: 90vh;
  margin-bottom: 5rem;
}
.left {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left h3 {
  font-weight: bolder;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
}
.inputs {
  width: 550px;
  padding: 15px 18px;
  border-radius: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 4px rgb(66, 59, 59);
}
.inputs:hover {
  box-shadow: 2px 3px 4px gray;
}
.inputs input {
  border: none;
  outline: none;
  width: 1200px;
  border-radius: 5px;
  background-color: transparent;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10rem;
}

.imgs {
  text-align: center;
  width: 310px;
  height: 310px;
  position: relative;
  margin-top: 6rem;
}

.imgs img {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  object-fit: cover;
}

.icons1 {
  position: absolute;
  top: -0.5rem;
  left: -2rem;
}
.icons2 {
  position: absolute;
  bottom: -0.5rem;
  right: -1rem;
}
.icons3 {
  position: absolute;
  top: -0.5rem;
  right: -2rem;
}
.icons4 {
  position: absolute;
  bottom: -0.5rem;
  left: -1rem;
}
.icons img {
  width: 60px;
  height: 60px;
  border: 2px solid wheat;
  border-radius: 50%;
  object-fit: contain;
}
